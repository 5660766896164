const hostname = window && window.location && window.location.hostname

const mainURL = 'https://api.top-court.com/admin'
// const stagingURL = 'https://staging-api.top-court.com/admin'
// const stagingURL = 'http://localhost:5100/admin'
const stagingURL = 'https://api-staging.top-court.com/admin'
// const stagingURL = 'https://api.top-court.com/admin'
const betaURL = 'https://beta-api.top-court.com/admin'

let adminUrl = mainURL

console.log('hostname', hostname)

if (hostname) {
    if (hostname.includes('beta-topcourtadmin.netlify.app')) {
        adminUrl = betaURL
    } else if (hostname.includes('staging-admin.top-court.com')) {
        adminUrl = stagingURL
    } else if (hostname.includes('admin.top-court.com')) {
        adminUrl = mainURL
    } else if (hostname.includes('localhost')) {
        adminUrl = stagingURL
    }
}

console.log('hostname', hostname, adminUrl)

export {
    adminUrl
}